import React from 'react'
import Map from 'pigeon-maps'
import Marker from 'pigeon-marker'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'

import coffee from '../images/contact_coffee.svg'
import phoneCall from '../images/contact_phone-call.svg'
import writing from '../images/contact_writing.svg'

const MAPTILER_ACCESS_TOKEN = '8AOiRm48vzs0Tbx4CTLG'
const MAP_ID = 'streets'

const mapTilerProvider = (x, y, z, dpr) => {
  return `https://api.maptiler.com/maps/${MAP_ID}/256/${z}/${x}/${y}${
    dpr >= 2 ? '@2x' : ''
  }.png?key=${MAPTILER_ACCESS_TOKEN}`
}

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        description="F2.net is an Italian React and Node.js web development agency. Launching a new project? Let's talk!"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact us"
      />
      <Hero>
        <h1 className="text-3xl sm:text-5xl text-center font-black leading-tight mb-3">
          Contact us
        </h1>
        <p className="text-xl text-center leading-relaxed text-gray-700">
          Launching a new project? Let's talk!
        </p>
      </Hero>

      <section className="px-6">
        <div className="flex flex-row flex-wrap max-w-sm sm:max-w-md md:max-w-xl lg:max-w-3xl mx-auto justify-center md:justify-around">
          <div className="px-6 pb-16 flex flex-col">
            <img
              alt="Map icon"
              className="w-24 h-24 mx-auto mb-6"
              src={coffee}
            />
            <p className="font-extrabold text-center">Have a coffee with us</p>
            <p className="text-gray-600 text-center">
              Via Milano, 57
              <br />
              Bonate Sopra (BG)
              <br />
              Italy
            </p>
          </div>
          <div className="px-6 pb-16 flex flex-col">
            <img
              alt="Map icon"
              className="w-24 h-24 mx-auto mb-6"
              src={phoneCall}
            />
            <p className="font-extrabold text-center">Let's talk</p>
            <p className="text-gray-600 text-center">+39 035 991465</p>
          </div>
          <div className="px-6 pb-16 flex flex-col">
            <img
              alt="Map icon6"
              className="w-24 h-24 mx-auto mb-6"
              src={writing}
            />
            <p className="font-extrabold text-center">Email us</p>
            <p className="text-gray-600 text-center">
              <a href="mailto:info@f2.net">info@f2.net</a>
            </p>
          </div>
        </div>
      </section>

      <section className="pb-20">
        <div className="max-w-3xl mx-auto">
          <Map
            center={[45.678309, 9.567007]}
            height={300}
            metaWheelZoom
            zoom={9}
            provider={mapTilerProvider}
            dprs={[1, 2]}
          >
            <Marker anchor={[45.678309, 9.567007]} payload="F2.net" />
          </Map>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
